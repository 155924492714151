/**
 * The list of all active experiments, with split name and all possible treatments.
 *
 * **When adding new experiments:**
 *   PLEASE MAKE SURE the list is still sorted alphabetically.
 */
export const experiments = {
  AA_test: ['on', 'off'],
  AI_Editor_Overlay: ['on', 'off'],
  AI_Insights_Assistant: ['on', 'off'],
  AI_Personalized_Builder: ['on', 'off'],
  AI_Presentation_Overlay: ['on', 'off'],
  Enterprise_Participants_SSO: ['on', 'off'],
  INS_Reset_Results_V2: ['on', 'off'],
  INT_Persistent_Present_Button: ['on', 'off'],
  Live_Connectivity_Notification: ['on', 'off'],
  Live_Identified_Responses: ['on', 'off'],
  Mango_Ownership_Transfer: ['on', 'off'],
  Mango_Use_Carry_To_Paid: ['on', 'off'],
  Monthly_Billing: ['on', 'off'],
  MX_Editor_Endpoints: ['on', 'off'],
  OG_Menti_Finish_Button_To_Endscreen: ['v1', 'v2', 'v3'],
  PAY_License_Selector_Experiment: ['v0', 'v1'],
  PAY_Plan_Name_Experiment: ['on', 'off'],
  PAY_Ppp_Rollout_User: ['on', 'off'],
  PM_CustomerIO_In_App_Messaging: ['on', 'off'],
  PUG_Braze: ['on', 'off'],
  PUG_Export_Results: ['v1', 'off'],
  PUG_New_Limit_Experience: ['new-banner', 'modal', 'off'],
  PUG_Notifications_Technical_Test: ['v1', 'v2', 'off'],
  PUG_Recommend_Best_Plan: ['v1', 'off'],
  PUG_Search: ['on', 'off'],
  PX_CSAT: ['on', 'off'],
  Ragnar_Dark_Mode_V1: ['on', 'off'],
  SLI_Power_Tools_Wordcloud_Grouping: ['on', 'off'],
  SLI_Power_Tools: ['on', 'off'],
  TREX_Moar_Folders: ['on', 'off'],
  TREX_Message_In_Invite: ['on', 'off'],
  USE_AI_Menti_Builder_v2: ['off', 'v1', 'v2', 'v3'],
  USE_Onboarding_Expanded_IMU: ['off', 'slice_2', 'slice_3'],
  USE_Onboarding_New_Page: ['on', 'off'],
  USE_Onboarding_Personalized_Start_Quick_Actions: [
    'off',
    'value',
    'functional',
  ],
  USE_Onboarding_Presenter_Pace_Step: ['on', 'off'],
  /**
   *   PLEASE MAKE SURE the list is still sorted alphabetically.
   */
} as const;

export type WwwExperiments = typeof experiments;
